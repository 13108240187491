const persistentData = {
    REACT_APP_BUILDNUMBER: '20240918.1',
    REACT_APP_RELEASENAME: 'main',
};

const metadata: Record<string, unknown> = {
    ...persistentData,
};

type ChangeCallback<T> = (newValue: T) => void;
const subscriptions: Record<string, ChangeCallback<unknown>[]> = {};

function shouldPersist(key: string): boolean {
    return Object.keys(persistentData).includes(key);
}

function setMetadata<T>(key: string, value: T): void {
    if (getMetadata(key) !== value) {
        metadata[key] = value;
        subscriptions[key] &&
            subscriptions[key].forEach(fn => fn.call(null, value));
    }
}

function getMetadata<T>(key: string): T | undefined {
    const cachedItem = metadata[key];
    return typeof cachedItem === 'undefined' ? undefined : (cachedItem as T);
}

function removeMetadata(key: string): void {
    !shouldPersist(key) && delete metadata[key];
}

function clearAllMetadata(): void {
    Object.keys(metadata).forEach(key => {
        removeMetadata(key);
    });
}

function subscribeToChanges<T>(key: string, callback: ChangeCallback<T>): void {
    if (!subscriptions[key]) {
        subscriptions[key] = [];
    }
    subscriptions[key].push(callback as ChangeCallback<unknown>);
}

function unsubscribeFromChanges<T>(
    key: string,
    callback: ChangeCallback<T>,
): void {
    if (subscriptions[key]) {
        subscriptions[key] = subscriptions[key].filter(cb => cb !== callback);
    }
}

export {
    setMetadata,
    getMetadata,
    removeMetadata,
    clearAllMetadata,
    subscribeToChanges,
    unsubscribeFromChanges,
};
